import 'jquery.marquee';
import { tns } from 'tiny-slider/src/tiny-slider';

// Styles SCSS
import '../sass/home.scss';

// SVG polyfiil
import 'svgxuse';

// LazyLoad
import lazyLoadInit from './lazyload-init';

lazyLoadInit();

console.log('home-js loaded');

var slider = tns({
	container: '.slider-bandeau',
	items: 1,
	autoplay: true,
	autoplayButtonOutput: false,
	nav: false,
	autoplayHoverPause: true,
	autoplayTimeout: 3500,
	autoplayText: [
		'<i class="fad fa-play"></i>',
		'<i class="fad fa-pause"></i>',
	],
	controlsText: [
		'<i class="fal fa-chevron-left"></i>',
		'<i class="fal fa-chevron-right"></i>',
	],
});

$('.ticker').marquee({
	duration: 13000,
	delayBeforeStart: 0,
	direction: 'left',
	duplicated: false,
	pauseOnHover: true,
});

jQuery('.search-toggle').on('click', function() {
	jQuery(this).toggleClass('active');
	jQuery('.searchform').slideToggle();
	jQuery('.searchform input').focus();
});

jQuery('.menu-responsive-icon').on('click', function() {
	console.log('click');
	jQuery('.responsive-menu').toggleClass('visible');
});

jQuery('.icon-share').on('click', function() {
	jQuery('.icons').toggleClass('visible');
});
